<template lang="pug">
settings-page(title="Тарифы")
  settings-panel
    template(#header) Тариф
    my-tariff
  div(style="margin-bottom: 64px;")
    tariff-selector
      template(#title) Другие тарифы
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import SettingsPage from "@/components/pages/settings/common/SettingsPage.vue";
import MyTariff from "@/components/pages/settings/tariffPage/MyTariff.vue";
import TariffSelector from "@/components/pages/settings/tariffPage/TariffSelector.vue";

export default defineComponent({
  name: "TariffPage",
  components: {
    SettingsPage,
    SettingsPanel,
    MyTariff,
    TariffSelector,
  },
});
</script>

<style scoped lang="scss">
</style>
